import React from "react";
import { Link } from "react-router-dom";

class PathLink extends React.Component {
    constructor(props) {
        super(props);

        this.path = props.pagePath
    }
    render() {
        return (
            <div>
                <ul className="breadcrumb">


                    {
                        this.path.map((data, i) => {
                            let page = data.page

                            if (page === 'home')
                                page = '/'
                            else {

                                 page = sessionStorage.getItem('page-' + page);

                                if (page !== data.page && data.page!=='current')
                                    data.title = sessionStorage.getItem('name-' + data.page);

                                    if(data.title2)
                                      data.title  = data.title2

                                    if(data.query)
                                       page = page+'?'+data.query
                            }
                            if (data.page !== 'current')
                                return <li><Link to={`${page}`}><i className={'fa fa-'+data.icon} aria-hidden="true"></i> {data.title}</Link></li>
                            else
                                return <li> <i className={'fa fa-'+data.icon} aria-hidden="true"></i> {data.title}</li>
                        }

                        )

                    }
                </ul>

            </div>
        )
    }

}

export default PathLink;